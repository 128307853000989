
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <form @submit.prevent="updateSalary" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="employees" v-model="salary.employee_id" dense  filled outlined  item-text="employee_id"
                                    item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="salary_list" v-model="salary.salary_list_id" dense  filled outlined  item-text="salary_list_id"
                                    item-value="salary_list_id" :return-object="false" :label="$store.getters.language.data.salary_list.salary_list_id">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_base" type="text" :label="$store.getters.language.data.salary.salary_base" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_total" type="text" :label="$store.getters.language.data.salary.salary_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_gov_inss" type="text" :label="$store.getters.language.data.salary.salary_gov_inss" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_fines_total" type="text" :label="$store.getters.language.data.salary.salary_fines_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_iligal_days_amount" type="number" :label="$store.getters.language.data.salary.salary_iligal_days_amount" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_iligal_days_total" type="text" :label="$store.getters.language.data.salary.salary_iligal_days_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_extra_hours_amount" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_amount" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_extra_hours_total" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_total" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_total_commission" type="text" :label="$store.getters.language.data.salary.salary_total_commission" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_inss_amount" type="text" :label="$store.getters.language.data.salary.salary_inss_amount" 
                            class="mx-1"  filled outlined dense required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_insert_date" type="datetime-local" :label="$store.getters.language.data.salary.salary_insert_date" 
                            class="mx-1"  filled outlined dense  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"    type="submit">{{$store.getters.language.data.salary.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/salary.request.js'
    export default {
        data() {
            return {
                salary: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            
                    employees(){
                        return this.$store.getters.employees_list
                    },
                    
                    salary_list(){
                        return this.$store.getters.salary_list_list
                    },
                    
        },
        mounted(){
            this.id = this.$route.params.id
            this.getOneSalary()
        },
        methods: {
            getOneSalary() {
                this.loading = true
                requests.getOneSalary(this.id).then(r =>{
                    if (r.status == 200) {
                        this.salary = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Salary',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Salary',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateSalary() {
                this.loading = true
                requests.updateSalary(this.id,this.salary).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: 'Salary Updated',
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Update Faild',
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Update Faild',
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    