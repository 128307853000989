
import axios from 'axios';
export default {
    
    async createSalary(params)  {
        return await axios.post(`salary/create` , params)
    },
    async createSalaryList(params)  {
        return await axios.post(`salary/create/list` , params)
    },
    async updateSalaryColumn(column , value , data)  {
        return await axios.put(`salary/update_list?${column}=${value}` , data)
    },
    async deleteSalaryList(list)  {
        return await axios.delete(`salary/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async getSalaryReport(query)  {
        return await axios.post(`salary/report` , query)
    },

    async getEmployeeDetails(query)  {
        return await axios.post(`salary/employee/` , query)
    },
    // async reportSalary(column , value)  {
    //     return await axios.post(`salary/report?${column}=${value}`)
    // },
    async getAllSalary()  {
        return await axios.post(`salary/all`)
    },
    async getOneSalary(salary_id)  {
        return await axios.post(`salary/all/${salary_id}`)
    },
    async getSalaryByColumn(column , value)  {
        return await axios.post(`salary/filter?column=${column}&value=${value}`)
    },
    async deleteSalary(salary_id)  {
        return await axios.delete(`salary/delete/${salary_id}`)
    },
    async updateSalary(salary_id , params)  {
        return await axios.put(`salary/update/${salary_id}` , params)
    }
}